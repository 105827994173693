<template>
  <div>
    <v-divider></v-divider>

    <HbPropertySelector
      id="property_id"
      :items="facilityList"
      item-text="name"
      item-value="id"
      v-validate="'required'"
      v-model.lazy="property_id"
      data-vv-scope="gate"
      data-vv-name="facility"
      data-vv-as="Property"
      :loading="loading"
      :disabled="loading"
    />

    <div class="mr-6">
      <hb-empty-state
        v-if="!property_id"
        :message="'Please select a Property to continue.'"
        :showBtn="false"
      >
      </hb-empty-state>

      <hb-data-table
        v-else
        :headers="headers"
        :items="loading ? [] : serviceKeys || keys"
        :loading="loading"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
      <template v-slot:item.enable="{ item }">
            <HbIcon class="ml-2" :mdi-icon="item.enable ? 'mdi-table-actions-enable' : 'mdi-table-actions-disable'" />
        </template>
      </hb-data-table>
    </div>
  </div>
</template>

<script>
import { HbSettingsPropertySelector } from "hummingbird-aviary";
import { mapGetters, mapActions } from "vuex";
import api from "../../assets/api.js";

export default {
  name: "Services",
  data() {
    return {
      property_id: null,
      headers: [
        { text: "Service", value: "name", width: "50%" },
        { text: "Enabled", value: "enable", width: "10%" },
      ],
      keys: [],
      serviceKeys: [],
      loading: false
    };
  },
  components: {

  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
        setDefaultProperty: 'propertiesStore/setDefaultProperty'
    }),
    async fetchData() {
      let mailhousesRequest = await api.get(
        this,
        api.MAILHOUSES + '/list'
      )

      for (let mailhouseNum = 0; mailhouseNum < mailhousesRequest.mailhouses.length; mailhouseNum++) {
        mailhousesRequest.mailhouses[mailhouseNum]["enable"] = false;
        mailhousesRequest.mailhouses[mailhouseNum]["created_at"] = "";
      }

      this.keys = mailhousesRequest.mailhouses
      this.property_id = this.defaultProperty ? this.defaultProperty.id : null;
    },
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      defaultProperty: "propertiesStore/defaultProperty",
      
    }),
    isPropertyLevel() {
      return this.level === "Property" ? true : false;
    },
  },
  watch: {
    property_id: async function() {
      this.loading = true;
      let propertiesMailhousesRequest;
      this.serviceKeys = JSON.parse(JSON.stringify(this.keys));
      if(this.property_id){
        propertiesMailhousesRequest = await api.get(
          this,
          api.PROPERTIES + this.property_id + '/mailhouses'
        )
      }

      for (let service = 0; service < this.keys.length; service++) {
        if (propertiesMailhousesRequest && propertiesMailhousesRequest.includes(this.keys[service].name)) {
            this.serviceKeys[service].enable = true
        }
      }
      this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id))
      this.loading = false;
    }
  }
};
</script>
